<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>资产管理</BreadcrumbItem>
                <BreadcrumbItem>资产设置</BreadcrumbItem>
                <BreadcrumbItem>资产位置</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <Input v-model="filter.keywords" placeholder="输入位置名称，编号" clearable />
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">查询</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-add" @click="add"  v-if="permissionButton.add">新增位置</Button>
                </FormItem>
                <FormItem>
                    <Button icon="md-cloud-download" v-if="permissionButton.import" @click="upload = true" >批量导入</Button>
                </FormItem>
                <FormItem>
                    <Modal v-model="upload" @on-cancel="cancel" title="导入">
                        <div class="import-data">
                            <Button class="download-btn" type="primary" icon="md-cloud-download" @click="downloadTemplate">下载Excel模板</Button>
                            <Upload class="upload-btn"
                                    ref="upload"
                                    :action="verifyAndSaveLocation"
                                    :format="['xlsx','xls']"
                                    accept=".xlsx,.xls"
                                    :show-upload-list="false"
                                    :before-upload="handleBeforeUpload"
                                    :on-success="handleSuccess"
                                    :on-error="handleImportError"
                                    :headers="header">
                                <Button type="primary" icon="md-cloud-upload" :loading="importing">导入Excel文件</Button>
                            </Upload>
                            <div class="import-tips">注意：导入数据前请先下载Excel模版，编辑后请点击导入Excel数据按钮上传</div>
                            <div class="import-errors" v-if="importErrorArray.length > 0">
                                <Table stripe :columns="importErrorColumns" :data="importErrorArray"></Table>
                            </div>
                        </div>
                        <div slot="footer">
                            <Button type="primary" @click="cancel">确定</Button>
                        </div>
                    </Modal>
                </FormItem>
            </Form>
        </div>
        <Split class="split-pane" min="300" v-model="splitPercent">
            <div slot="left" class="split-pane-left">
                <div class="v-tree">
                    <Tree :data="locationList" :render="renderContent" ></Tree>
                </div>
            </div>
            <div slot="right" class="split-pane-right">
                <div class="table">
                    <div class="table-list">
                        <Table stripe :columns="columns" :data="locationArray">
                            <template slot-scope="{row,index}" slot="action">
                                <a @click="edit(row,index)" v-if="permissionButton.edit">编辑</a>
                                <Divider type="vertical"/>
                                <a class="errors" @click="del(row,index)" v-if="permissionButton.del">删除</a>
                            </template>
                        </Table>
                        <showEdit v-model="editShow" :id="id" :location="location" :row="row" :treeSelectOpts="treeSelectOpts" v-on:getPage="getPage" v-on:getLocation="getLocation"></showEdit>
                        <showAdd v-model="addShow" :pId="parentId" :treeSelectOpts="treeSelectOpts" v-on:getPage="getPage" v-on:getLocation="getLocation"></showAdd>
                    </div>
                    <div class="table-footer">
                        <Row>
                            <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                            <Col class="pagenation" span="18">
                                <Page :page-size="pageSize"
                                    :total="total"
                                    :current="pageNum"
                                    :page-size-opts="[15,20,50,100]"
                                    @on-change="changePage"
                                    @on-page-size-change="changPageSize"
                                    show-sizer
                                    show-elevator/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </Split>
    </div>
</template>

<script>
import permission from '@/common/permission';
import {reqVerifyAndSaveLocation, reqDownloadExcelTemplate} from '../../../../api/exportToExcel-api';
import {reqGetLocation, reqGetLocationList, reqDelLocation, reqGetLocationChildListById} from '../../../../api/asset-api';
import showEdit from './edit.vue';
import showAdd from './add.vue';

export default {
    name: 'locationList',
    components: {
        showEdit,
        showAdd
    },
    data() {
        return {
            verifyAndSaveLocation: reqVerifyAndSaveLocation,
            id: '',
            splitPercent: 0.2,
            locationArray: [],
            treeSelectOpts: [],
            selectedId: '',
            editShow: false,
            addShow: false,
            upload: false,
            tree: false,
            importing: false,
            filter: {
                keywords: ''
            },
            location: null,
            row: null,
            parentId: null,
            permissionButton: {
                add: false,
                edit: false,
                del: false,
                view: false,
                import: false
            },
            total: 0,
            pages: 1,
            pageNum: 1,
            pageSize: 15,
            editState: false, // 编辑状态
            locationList: [],
            header: {},
            importErrorArray: [],
            importErrorColumns: [{
                type: 'index',
                width: 60,
                align: 'center'
            },{
                title: '错误信息',
                key: 'error'
            }],
            columns: [
                {
                    title: "位置编号",
                    key: 'serialNumber',
                    tooltip: 'ture',
                    width: 200
                },
                {
                    title: "位置名称",
                    key: 'name',
                    tooltip: 'ture',
                    minWidth: 200
                },
                {
                    title: "上级分类",
                    key: 'parentName',
                    tooltip: 'ture',
                    width: 200
                },
                {
                    title: "备注",
                    key: 'memo',
                    tooltip: 'ture',
                    width: 200
                },
                {
                    title: "创建时间",
                    key: 'createTime',
                    tooltip: 'ture',
                    width: 200
                },
                {
                    title: "操作",
                    slot: 'action',
                    width: 150
                }
            ]
        }
    },
    methods: {
        edit(row,index){
            this.row = row;
            this.editShow = true
            },
        add(row,index){
            this.parentId = null;
            this.addShow = true
            },
        del(row,index){
            this.$Modal.confirm({
                title: "删除资产位置",
                content: "是否删该除资产位置及全部下级位置",
                width: "240",
                onOk: () => {
                    reqDelLocation({id: row.id}).then((res)=>{
                        if(res.data.code === 1){
                            this.getPage();
                            this.getLocation();
                            this.$Message.success("删除成功")
                        }else{
                            this.$Message.success("删除失败")
                        }
                    })
                }
            })
        },
        getPage(){
            this.loading = true;
            let para = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                keywords: this.filter.keywords,
            }
            reqGetLocationList(para).then((res)=>{
                this.loading = false;
                this.locationArray = res.data.data.records;
                this.pageNum = res.data.data.current;
                this.pages = res.data.data.pages;
                this.total = res.data.data.total;
            }).catch(()=>{
                this.pageNum = '';
                this.pages = '';
                this.total = '';
                this.locationArray = []
            })
        },
        getLocation(){
            reqGetLocation().then((res) => {
                    this.locationList = res.data.data;
                    this.treeSelectOpts = res.data.data;
            }).catch(()=>{
                this.locationList = [];
            });
        },
        downloadTemplate() {
            let fileName = 'Location.xlsx';
            reqDownloadExcelTemplate({fileName: fileName}).then(res => {
                const link = document.createElement('a');
                const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
                link.style.display = 'none';
                link.href = URL.createObjectURL(blob);
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch(() => {
                this.$Message.error("文件不存在");
            });
        },
        handleBeforeUpload(file) {
            this.importing = true;
            this.importErrorArray = [];
        },
        handleImportError(error, file, fileList) {
            this.importing = false;
            this.$Message.error("导入失败");
        },
        handleSuccess(response) {
            this.importing = false;
            if (response.status === 1) {
                this.$Message.success("导入成功");
                this.getPage();
                this.getLocation();
            } else {
                this.importErrorArray = [];
                JSON.parse(response.errors).forEach(item => {
                    this.importErrorArray.push({error: item})
                });
                this.$Message.error("导入失败");
            }
        },
        cancel() {
            this.upload = false;
            this.importing = false;
            this.importErrorArray = [];
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            if(this.tree == true){
                this.getChildListById();
            }else{
                this.getPage();
            }
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            if(this.tree === true){
                this.getChildListById();
            }else{
                this.getPage();
            }
        },
        // 树渲染逻辑
        renderContent (h, { root, node, data }) {
            return h('span', {
                class: 'common-wrap',
                on:{
                    click:() => {
                        // 点击Tree节点触发
                        if (!data.editState) {
                            this.handleClickTreeNode(root, data, node)
                        }
                    }
                }
            }, [
                h('span', [
                    // 文件前面的文件夹图标
                    h('Icon', {
                        props: {
                            type: `${data.children === undefined || data.children.length === 0 ? 'md-document' :'ios-folder'}`
                        },
                        style: {
                            marginRight: '8px',
                        }
                    }),
                    h(`${ data.editState ? '' : 'span'}`, data.title),
                    h(`${ data.editState ? 'input' : ''}`,
                        {
                            class: 'edit-input',
                            attrs:{
                                value:`${ data.editState ? data.title : ''}`,
                                autofocus: 'true'
                            },
                            style: {
                                // cursor: 'auto' ,
                                // borderRadius: '3px',
                                // border: '1px solid #e5e5e5',
                                // color: '#515a6e'
                            },
                            on:{
                                change: (event) => {
                                    this.inputContent = event.target.value
                                }
                            }
                        }
                    )
                ]),
                // 增删改按钮部分
                h(`${ data.editState ? '' : 'span'}`,
                    {
                        class: 'btnNone'
                    },
                    [
                        // 操作按钮部分
                        // 编辑按钮
                        h('Button', {
                            props: {
                                ...this.buttonProps,
                                icon: 'md-color-filter'
                            },
                            on: {
                                click: () => { this.editTree(data) }
                            }
                        }),
                        // 添加按钮
                        h('Button', {
                            props: {
                                ...this.buttonProps,
                                icon: 'md-add'
                            },
                            on: {
                                click: () => { this.append(data) }
                            }
                        }),
                        // 删除按钮
                        h('Button', {
                            props: {
                                ...this.buttonProps,
                                icon: 'md-remove'
                            },
                            on: {
                                click: () => { this.remove(root, node, data) }
                            }
                        })
                    ]
                ),
                // 确认/取消修改部分
                h(`${ data.editState ? 'span' : ''}`,
                    {
                        style: {
                            marginLeft: '.5rem'
                        }
                    },
                    [
                        // 确认按钮
                        h('Button', {
                            props: {
                                ...this.buttonProps,
                                icon: 'md-checkmark'
                            },
                            style: {
                                border: 0,
                                background: 'rgba(0,0,0,0)',
                                fontSize: '16px',
                                outline: 'none'
                            },
                            on: {
                                click: (event) => {
                                    this.confirmTheChange(data)
                                }
                            }
                        }),
                        // 取消按钮
                        h('Button', {
                            props: {
                                ...this.buttonProps,
                                icon: 'md-close'
                            },
                            style: {
                                border: '0',
                                background: 'rgba(0,0,0,0)',
                                fontSize: '16px',
                                outline: 'none'
                            },
                            on: {
                                click: () => { this.cancelChange(data) }
                            }
                        })
                    ]
                )
            ])
        },
        // 控制Tree当前状态函数
        setStates(data){
            let editState=data.editState
            if (editState) {
                this.$set(data, 'editState', false)
            } else {
                this.$set(data, 'editState', true)
            }
        },
        // Tree修改按钮
        editTree(data){
            this.location = data;
            this.editShow = true;
        },
        // 添加按钮
        append (data) {
            this.parentId = data.id;
            this.addShow = true;
        },
        // 删除按钮
        remove (root, node, data) {
            this.$Modal.confirm({
                title: "删除资产位置",
                content: "是否删该除资产位置及全部下级位置",
                width: "240",
                onOk: () => {
                    reqDelLocation({id: data.id}).then((res)=>{
                        if(res.data.code === 1){
                            this.getPage();
                            this.getLocation();
                            this.$Message.success("删除成功")
                        }else{
                            this.$Message.success("删除失败")
                        }
                    })
                }
            })
        },
        // 确认修改树节点
        confirmTheChange(data) {
            if (!this.inputContent) {
                this.$Notice.warning({
                    title: '当前输入有误',
                })
            } else {
                if(this.oldName !== this.inputContent){
                    this.$Modal.confirm({
                        title: '提示',
                        content: `您确定将  “${this.oldName}”  重命名为 “ ${this.inputContent} ” 吗？`,
                        onOk: () => {
                            data.title=this.inputContent
                            this.$Message.info('修改成功')
                        },
                        onCancel: () => {
                            this.$Message.info('取消')
                        }
                    })
                    this.setStates(data)
                } else{
                    this.setStates(data)
                }
            }
        },
        // 取消修改树节点
        cancelChange(data) {
            this.$Notice.info({
                title: '取消修改',
            })
            this.setStates(data)
        },
        getChildListById() {
            if (!this.selectedId) {
                this.locationArray = [];
            } else {
                reqGetLocationChildListById({id: this.selectedId}).then(res => {
                    this.total = res.data.data.length;
                    if(this.total > this.pageSize){
                        if(this.total%this.pageSize !== 0){
                            this.pages = parseInt(this.total/this.pageSize) + 1;
                            this.locationArray = res.data.data.slice((this.pageNum-1)*this.pageSize,parseInt(this.total/this.pageSize) === this.pages - 1 ? this.pageNum*this.pageSize:this.total);
                        }else{
                            this.pages = this.total/this.pageSize
                            this.locationArray = res.data.data.slice((this.pageNum-1)*this.pageSize,this.pageNum*this.pageSize)
                        }
                    }else{
                        this.pages = 1;
                        this.locationArray = res.data.data;
                    }
                }).catch(()=>{
                    this.locationArray = [];
                });
            }
        },
        // 点击Tree节点触发
        handleClickTreeNode(root, data, node) {
            this.selectedId = data.id;
            this.tree = true;
            this.pageNum = 1;
            this.getChildListById();
        },
        // js 递归遍历查找对象数组的某一个属性
        filterTableMater(code, arr) {
            for (const item of arr) {
                if (item.id === code) {
                    item.selected = true
                }
                if (item.children && item.children.length) {
                    this.filterTableMater(code, item.children)
                }
            }
        },
        doSearching(){
            this.getPage();
        }
    },
    mounted() {
        this.getLocation();
        this.getPage();
        let token = sessionStorage.getItem('Authorization');
        this.header = {Authorization: token};
        let permissionId = this.$route.query.permissionId;
        permission.getPermissionButton(permissionId, this.permissionButton);
    }
}
</script>
