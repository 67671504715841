<template>
    <Modal v-model="showForm" title="编辑资产位置" :mask-closable="false">
        <Form ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="90">
            <FormItem label="位置编号" prop="serialNumber">
                <Input v-model="editForm.serialNumber" :maxlength="30" placeholder="位置编号" clearable />
            </FormItem>
            <FormItem label="位置名称" prop="name">
                <Input v-model="editForm.name" :maxlength="30" placeholder="位置名称" clearable />
            </FormItem>
            <FormItem label="上级位置" prop="parentId">
                <treeselect v-model="editForm.parentId" :options="treeSelectOpts" placeholder="上级位置" noOptionsText="暂无数据"  noResultsText="没有匹配数据" :disabled="editForm.parentId === null" clearable />
            </FormItem>
            <FormItem label="备注">
                <Input v-model="editForm.memo" type="textarea" :maxlength="200" placeholder="备注" clearable />
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {getChildrenById} from "../../../../assets/js/treeFun";
import {reqEditLocation} from '../../../../api/asset-api';
export default {
    name: "editLocation",
    components: {Treeselect},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        location: {},
        row: '',
        treeSelectOpts: []
    },
    data() {
        return {
            showForm: this.value,
            loading: false,
            locationList: [],
            locationArray: [],
            invalidParentId: '',
            editForm: {
                id: '',
                name: '',
                parentId: '',
                memo: '',
                serialNumber: ''
            },
            editFormRule: {
                name:[
                    {required: true, message: '请输入位置名称', trigger: 'blur'}
                ],
                serialNumber:[
                    {required: true, message: '请输入位置编号', trigger: 'blur'}
                ],
                parentId: [{
                    trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (this.editForm.parentId) {
                            let childrenIds = [];
                            childrenIds = [...childrenIds, ...getChildrenById(this.treeSelectOpts, this.invalidParentId, [])];
                            if (childrenIds.includes(this.editForm.parentId)) {
                                return callback(new Error("不能选择本级分类及其下级分类"));
                            }
                        }
                        return callback();
                    }
                }]
            }
        }
    },
    methods: {
        handleSubmit() {
            this.$refs["editForm"].validate((valid)=>{
                if(valid){
                    let paras = {
                        id: this.editForm.id,
                        locationId: this.editForm.parentId,
                        memo: this.editForm.memo,
                        name: this.editForm.name,
                        serialNumber: this.editForm.serialNumber
                    }
                    reqEditLocation(paras).then((res)=>{
                        this.loading = false;
                        if(res.data.code === 1){
                            this.closeModal(false);
                            this.$emit("getPage");
                            this.$emit("getLocation");
                            this.$Message.success('编辑成功');
                        } else if (res.data.code === -1) {
                            this.$Message.error("位置名称已存在");
                        } else if (res.data.code === -2) {
                            this.$Message.error("不能选择本级位置及其下级位置");
                        } else if (res.data.code === -3) {
                            this.$Message.error("位置编号已存在");
                        } else if (res.data.code === -4) {
                            this.$Message.error("上级位置不能为空");
                        } else if(res.data.code === 0){
                            this.$Message.error('编辑失败');
                        }
                    }).catch(()=>{
                        this.loading = false;
                        this.$Message.error('编辑失败');
                    })
                }
            })
        },
        // 所在位置
        // getLocation(){
        //     reqGetLocation().then((res)=>{
        //         this.locationArray = res.data.data;
        //     }).catch(()=>{
        //         this.locationArray = [];
        //     });
        // },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                this.editForm = {};
                this.invalidParentId = '';
                if(this.row !== null){
                    this.editForm.id = this.row.id;
                    this.editForm.name = this.row.name;
                    this.editForm.parentId = this.row.parentId;
                    this.editForm.memo = this.row.memo;
                    this.editForm.serialNumber = this.row.serialNumber;
                }
                if(this.row == null){
                    this.invalidParentId = this.location.id;
                }else{
                    this.invalidParentId = this.row.id;
                }
                Object.assign(this.editForm, this.location);
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
