<template>
    <Modal v-model="showForm" title="新增资产位置" :mask-closable="false">
        <Form ref="addForm" :model="addForm" :rules="addFormRule" label-colon :label-width="90">
            <FormItem label="位置编号" prop="serialNumber">
                <Input v-model="addForm.serialNumber" :maxlength="30" placeholder="位置编号" clearable />
            </FormItem>
            <FormItem label="位置名称" prop="name">
                <Input v-model="addForm.name" :maxlength="30" placeholder="位置名称" clearable />
            </FormItem>
            <FormItem label="上级位置">
                <treeselect v-model="addForm.parentId" :options="treeSelectOpts" placeholder="上级位置" noOptionsText="暂无数据"  noResultsText="没有匹配数据" clearable />
            </FormItem>
            <FormItem label="备注">
                <Input v-model="addForm.memo" type="textarea" :maxlength="200" placeholder="备注" clearable />
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>

import {reqAddLocation} from '../../../../api/asset-api';
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
    name: "addLocation",
    components: {Treeselect},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        pId: null,
        treeSelectOpts: []
    },
    data() {
        return {
            showForm: this.value,
            loading: false,
            addForm: {
                name: '',
                parentId: null,
                memo: '',
                serialNumber: ''
            },
            addFormRule: {
                name:[
                    {required: true, message: '请输入位置名称', trigger: 'blur'}
                ],
                serialNumber:[
                    {required: true, message: '请输入位置编号', trigger: 'blur'}
                ]
            }
        }
    },
    methods: {
        handleSubmit() {
            this.$refs["addForm"].validate((valid)=>{
                if(valid){
                    let paras = {
                        locationId: this.addForm.parentId,
                        memo: this.addForm.memo,
                        name: this.addForm.name,
                        serialNumber: this.addForm.serialNumber
                    }
                    reqAddLocation(paras).then((res)=>{
                        this.loading = false;
                        if(res.data.code === 1){
                            this.closeModal(false);
                            this.$emit("getPage");
                            this.$emit("getLocation");
                            this.$Message.success('添加成功');
                        } else if (res.data.code === -1) {
                            this.$Message.error("位置名称已存在");
                        } else if (res.data.code === -2) {
                            this.$Message.error("位置编号已存在");
                        } else if (res.data.code === -3) {
                            this.$Message.error("上级位置不能为空");
                        } else{
                            this.$Message.error('添加失败');
                        }
                    }).catch(()=>{
                        this.loading = false;
                        this.$Message.error('添加失败');
                    })
                }
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['addForm'].resetFields();
                this.addForm.parentId = this.pId;
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
